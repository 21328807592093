import Icon from 'components/Icon'
import Link from 'components/Link'
import Input from 'components/forms/Input'
import Label from 'components/forms/Label'
import { graphql, useStaticQuery } from 'gatsby'
import useLayout from 'hooks/useLayout'
import { ReactComponent as Logo } from 'images/owyn-logo.svg'
import PropTypes from 'prop-types'
import React from 'react'
import theme from 'styles/theme'

const Footer = () => {
  const layout = useLayout()
  const {
    footer: {
      firstColumnTitle,
      firstColumnLinks,
      secondColumnTitle,
      secondColumnLinks,
      emailColumnTitle,
      emailColumnText,
      formName,
      formAction,
      redirectUrl,
    },
  } = useStaticQuery(graphql`
    {
      footer: sanityFooter {
        firstColumnLinks {
          _key
          text
          url
        }
        firstColumnTitle
        secondColumnTitle
        secondColumnLinks {
          _key
          text
          url
        }
        emailColumnTitle
        emailColumnText
        formName
        formAction
        redirectUrl
      }
    }
  `)

  if (!layout.showFooter) {
    return null
  }

  return (
    <footer
      css={{
        position: 'relative',
        backgroundColor: theme.colors.cabbage,
        color: theme.colors.tumericLight,
        padding: '0 0 4.5rem',

        [theme.large]: {
          padding: '4.5rem 0',
        },
      }}
    >
      <div
        className='Container'
        css={{
          position: 'relative',
          zIndex: '1',
          maxWidth: theme.maxContainerWidth,
          margin: '0 auto',
          ...theme.containerPadding,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <ul
          css={{
            margin: 0,
            padding: 0,
            listStyle: 'none',

            [theme.medium]: {
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            },

            '> li': {
              marginBottom: '3rem',
              [theme.large]: {
                flex: 1,
              },
            },

            ul: {
              margin: 0,
              padding: 0,
              listStyle: 'none',
            },

            a: {
              textDecoration: 'none',
              cursor: 'pointer',
              padding: '.75rem 0',
              display: 'flex',
              alignItems: 'center',
              transition: theme.fastTransition,
              ':hover': {
                opacity: '.5',
              },
            },
          }}
        >
          <li
            className='EmailNewsletter'
            css={{
              padding: '4.5rem 24px',
              margin: '0 -24px',

              [theme.mediumOnly]: {
                width: '110%',
              },
              [theme.large]: {
                order: '1',
                padding: 0,
                margin: 0,
                flex: '1.5 !important',
              },
            }}
          >
            <span
              css={{
                ...theme.smallcaps,
                display: 'block',
                color: 'inherit',
                marginBottom: '1.5rem',
              }}
            >
              {emailColumnTitle}
            </span>
            <p css={{ maxWidth: '32em' }}>{emailColumnText}</p>
            <form name={formName} method='POST' action={formAction}>
              {/* Klaviyo Newsletter List ID */}
              {/* formName passes the klaviyo list ID for now */}
              <input type='hidden' name='g' value={formName} />

              <p>
                <Label name='NewsletterEmail' styles={{ display: 'block' }}>
                  Email Address
                  <Input
                    id='NewsletterEmail'
                    // id="k_id_email"
                    type='email'
                    name='email'
                    placeholder='joe@example.com'
                    required={true}
                    styles={{
                      border: `1px solid ${theme.colors.tumericLight}`,
                      height: 48,
                      backgroundColor: 'transparent',
                      borderRadius: 3,
                      display: 'block',
                      width: '100%',
                      color: 'inherit',
                      padding: '0 1rem',
                      margin: '1rem 0',
                      ':hover': {
                        border: `1px solid ${theme.colors.tumeric}`,
                      },
                    }}
                  />
                  {/* Redirect taken care by form's action */}
                  {/* <input type="hidden" name="redirecturl" value={redirectUrl} /> */}
                  <input type='hidden' name='optin' value='true' />
                </Label>
              </p>
              <Link
                theme='button'
                css={{
                  backgroundColor: theme.colors.tumericLight,
                  color: theme.colors.black100,
                  '&:hover': {
                    backgroundColor: theme.colors.tumeric,
                  },
                }}
              >
                Subscribe →
              </Link>
            </form>
          </li>
          <li>
            <span
              css={{
                ...theme.smallcaps,
                display: 'block',
                color: 'inherit',
                marginBottom: '1.5rem',
              }}
            >
              Let&apos;s be friends
            </span>
            <ul>
              <li>
                <Link to='https://www.facebook.com/onlywhatyouneed'>
                  <Icon.Facebook
                    width={24}
                    height={24}
                    aria-hidden='true'
                    css={{ marginRight: '.5rem' }}
                  />
                  @onlywhatyouneed
                </Link>
              </li>
              <li>
                <Link to='https://www.instagram.com/liveowyn/'>
                  <Icon.Instagram
                    width={24}
                    height={24}
                    aria-hidden='true'
                    css={{ marginRight: '.5rem' }}
                  />
                  @liveowyn
                </Link>
              </li>
            </ul>
          </li>
          <LinkColumn title={firstColumnTitle} links={firstColumnLinks} />
          <LinkColumn title={secondColumnTitle} links={secondColumnLinks} />
        </ul>

        <Link
          to='/'
          css={{
            display: 'inline-block',
            marginBottom: '4.5rem',
            [theme.large]: {
              order: '-1',
              alignSelf: 'flex-start',
            },
          }}
        >
          <Logo fill='currentColor' title='OWYN | Only What You Need' />
          <span className='sr-only'>OWYN | Only What You Need</span>
        </Link>
        {(layout.showFDADisclaimer || layout.showSecondDisclaimer) && (
          <div
            css={{
              maxWidth: '600px',
              marginBottom: '1rem',
              p: {
                fontSize: '80%',
                marginTop: 0,
              },
            }}
          >
            {layout.showFDADisclaimer && (
              <p>
                *These statements have not been evaluated by the Food and Drug Administration. These
                statements and the products of this company are not intended to diagnose, treat,
                cure or prevent any disease.
              </p>
            )}
            {layout.showSecondDisclaimer && <p>**Not a low calorie food</p>}
          </div>
        )}
        <small>&copy; OWYN {new Date().getFullYear()}. All Rights Reserved.</small>
      </div>
    </footer>
  )
}

const LinkColumn = ({ title, links }) => (
  <li>
    <span
      css={{
        ...theme.smallcaps,
        display: 'block',
        color: 'inherit',
        marginBottom: '1.5rem',
      }}
    >
      {title}
    </span>
    <ul>
      {links.map(link => (
        <li key={link._key}>
          {link.text === 'Accessibility' ? (
            <a className='link' data-acsb-custom-trigger='true'>
              Accessibility
            </a>
          ) : (
            <Link to={link.url}>{link.text}</Link>
          )}
        </li>
      ))}
    </ul>
  </li>
)

LinkColumn.propTypes = {
  title: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string,
    })
  ).isRequired,
}

export default Footer
